import {
  Box,
  Divider,
  Flex,
  Image,
  Select,
  Text,
  VStack,
  useBoolean,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { PAYMENT_TYPES } from "@/constants/";
import FAIcon from "@/components/FAIcon";
import Header from "@/components/Header";
import CreditCardView from "@/components/Payments/CreditCardView";
import ApplePayView from "@/components/Payments/ApplePayView";
import GooglePayView from "@/components/Payments/GooglePayView";
import Currency from "@/components/Currency";
import AuthContext from "@/context/auth";
import useAPI from "@/libs/hooks/api";
import MasterCard from "@/assets/images/payment_methods/MasterCard.png";
import VISA from "@/assets/images/payment_methods/VISA.png";
import JCB from "@/assets/images/payment_methods/JCB.png";

import useNavigateBack from "@/libs/hooks/navigate-back";

const PaymentLayout = () => {
  const { t } = useTranslation();
  const navigateBack = useNavigateBack();
  const { user } = useContext(AuthContext);
  const api = useAPI();
  const location = useLocation();
  const isDeposit = location.pathname.includes("deposit");

  const [method, setMethod] = useState(PAYMENT_TYPES.CREDIT_CARD);
  const [applePayAvailable, setApplePayAvailable] = useBoolean(false);
  const [googlePayAvailable, setGooglePayAvailable] = useBoolean(false);
  const [currency, setCurrency] = useState("TWD");
  const [exchangeRates, setExchangeRates] = useState({});
  const [order, setPayment] = useState(null);

  const supportedMethods = useMemo(() => {
    const methods = [PAYMENT_TYPES.CREDIT_CARD];
    if (isDeposit) {
      if (applePayAvailable) methods.push(PAYMENT_TYPES.APPLE_PAY);
      if (googlePayAvailable) methods.push(PAYMENT_TYPES.GOOGLE_PAY);
    }
    return methods;
  }, [applePayAvailable, googlePayAvailable, isDeposit]);

  const orderDetails = useMemo(() => {
    const { type, cost = 0, discount = 0, points = 0, plan } = order || {};

    let entry = t(`common.vip_plans.${plan?.replaceAll("-", "_")}`);
    if (type === "deposit")
      entry = t("payment.profile.purchase", {
        currency: t("common.currency"),
      });

    return {
      type,
      plan,
      variant: type === "deposit" ? "update_balance" : "subscribe",
      currency,
      cost: cost - discount,
      amount: points,
      entry,
    };
  }, [order, t, currency]);

  const payWith = useCallback(
    (key) => key && order && method === PAYMENT_TYPES[key.toUpperCase()],
    [order, method],
  );

  const onCancel = useCallback(() => setPayment(null), []);
  const onSelect = useCallback((data) => setPayment(data), []);

  useEffect(() => {
    api.getExchangeRate().then(({ data }) => setExchangeRates(data));
  }, [api]);

  return (
    <Box overflowX="hidden">
      {payWith("credit_card") ? (
        <Header>
          <Box />
          <Text fontSize="2xl" color="white" m="auto">
            {t("profile.payment.select_card")}
          </Text>
          <FAIcon
            type="close"
            role="button"
            fontSize="2xl"
            onClick={onCancel}
          />
        </Header>
      ) : (
        <Header>
          <Box />
          <FAIcon
            type="chevron-left"
            role="button"
            fontSize="2xl"
            onClick={navigateBack}
          />
          <Text fontSize="2xl" color="white" m="auto">
            {t("profile.payment.title")}
          </Text>
        </Header>
      )}

      <Box bg="#151515" px={4}>
        <VStack color="white" gap={4} align="stretch">
          <Flex justify="space-between" pr={3}>
            <Text>
              {t("profile.payment.currencies_owned", {
                currency: t("common.currency"),
              })}
            </Text>
            <Flex align="center" gap={2}>
              <Text color="secondary.100">{user.points?.balance}</Text>
              <Currency size={5} />
            </Flex>
          </Flex>
          <Flex justify="space-between">
            <Text>{t("profile.payment.select_currency")}</Text>
            <Select
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
              variant="unstyled"
              color="secondary.100"
              width={20}
              textAlign="right"
            >
              <option key={currency} value="TWD">
                TWD
              </option>
              {Object.keys(exchangeRates)
                .sort()
                .map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
            </Select>
          </Flex>
          <Flex justify="space-between">
            <Text>{t("profile.payment.select_payment_type")}</Text>
            <Select
              value={method}
              onChange={(e) => setMethod(e.target.value)}
              variant="unstyled"
              width={32}
              color="secondary.100"
              textAlign="right"
            >
              {Object.values(supportedMethods).map((payment) => (
                <option
                  key={payment}
                  value={payment}
                  onClick={() => setMethod(payment)}
                >
                  {t(`common.payment.${payment}`)}
                </option>
              ))}
            </Select>
          </Flex>
        </VStack>
        <Flex
          align="center"
          justify="center"
          width="fit-content"
          bg="#484848"
          gap={1}
          mx="auto"
          mt={4}
          py={1}
          px={3}
          borderRadius={16}
        >
          <Text wordBreak="keep-all" fontSize="xs" color="white">
            {t("profile.payment.supported_methods")}
          </Text>
          <Image src={VISA} width={6} />
          <Image src={MasterCard} width={6} />
          <Image src={JCB} width={6} />
        </Flex>
        <Box py={6}>
          <Divider size="xl" />
        </Box>
        <Box>
          <CreditCardView
            active={payWith("credit_card")}
            details={orderDetails}
            onCancel={onCancel}
          />
          <ApplePayView
            active={payWith("apple_pay")}
            details={orderDetails}
            onAvailable={setApplePayAvailable.on}
            onCancel={onCancel}
          />
          <GooglePayView
            active={payWith("google_pay")}
            details={orderDetails}
            onAvailable={setGooglePayAvailable.on}
            onCancel={onCancel}
          />
        </Box>

        {payWith("credit_card") || (
          <Outlet
            context={{
              currency,
              exchangeRates,
              onSelect,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PaymentLayout;
